.card {
  flex: 1 1 300px;
  position: relative;
  background-color: var(--colours-background-card);
  box-shadow: 2px 2px 40px hsl(var(--muted) / .5);
  padding: 10px;
  border-radius: 25px;
  border: var(--borders-border1px);
  max-width: var(--content);
  color: var(--colours-card-text);
  margin: 10px 60px 10px;
}

.body {
  padding-left: 80px;
  padding-right: 80px;
  text-align: left;
}

.image {
  border-radius: 25px;
  padding-bottom: 5px;
  height: 700px;
  width: auto;
}

@media screen and (max-width: 500px) {
  .card {
    margin: 0;
  }
}