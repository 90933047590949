:root {
  --colours-main: #784ffe;
  --colours-heading-text: #FEFEFF;
  --colours-card-text: #C7D3DB;
  --colours-background: #0C0F12;
  --colours-background-navigation: #191D1E;
  --colours-background-accent: black;
  --colours-background-card: #191D1E;
  --colours-palette-grey-300: #b5bec5;
  --colours-palette-grey-800: #181c1f;
  --font-sizes-h3: 24px;
  --font-sizes-p: 16px;
  --line-heights-h3: 24px;
  --line-heights-p: 16px;
  --borders-border1px: 1px solid #32383d;
}

.app {
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colours-background);
  color: var(--colours-card-text);
}
