.nav-bar {
  display: flex;
  margin-bottom: 20px;
}

.navigation-header {
  font-family: cursive;
  background-color:	var(--colours-background-navigation);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(26px + 1vw);
  color: var(--colours-main);
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--colours-background-navigation);
  border-bottom: 1px solid var(--colours-background-navigation);
  flex: auto;
}

.brand-name {
  text-decoration: none;
  color: var(--colours-background-navigation);
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  color: var(--colours-background-navigation);
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 1.35em;
}

.navigation-menu li a:hover {
  color: #0A0;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color:	var(--colours-background-navigation);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: var(--colours-main);
}

@media screen and (max-width: 550px) {
  .hamburger {
    display: block;
  }
  .ltc-logo {
    width: 32px;
    padding-right: 10px;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: 	var(--colours-background-navigation);
    border-top: 1px solid	var(--colours-background-navigation);
    display: none;
    z-index: 1;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: var(--colours-background-navigation);
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: var(--colours-background-navigation);
  }

  .navigation-menu.expanded ul {
    display: block;
  }
}
