.container {
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    color: var(--colours-card-text);
    padding-right: 5px;
  }
  > span a {
    color: var(--colours-card-text);
  }  
}